
import { Component, Prop, Vue } from 'vue-property-decorator';
import { ColumnHeader } from '@/interfaces/components/ColumnHeader';
import VTable from '@/components/shared/table/VTable.vue';
import { Table } from '@/interfaces/models/Table';
import headers from './headers';
import { Client } from '@/interfaces/models/Client';
import { Venue } from '../../interfaces/models/Venue';
import { Role } from '../../interfaces/models/Role';

@Component({
  components: { VTable },
})
export default class TagTable extends Vue {
  @Prop({ type: Array, required: true }) public items!: Client[];
  @Prop({ type: Array, required: true }) public venues!: Venue[];
  @Prop({ type: Array, required: true }) public roles!: Role[];
  @Prop({ type: Number, required: true }) public total!: number;

  public headers: ColumnHeader[] = headers;

  public edit(item: Client) {
    this.$router.push({ name: 'client.edit', params: { id: item._id }, query: { ...this.$route.query } });
  }

  public addSecret(item: Client) {
    this.$emit('add-secret', item);
  }

  public viewSecrets(item: Client) {
    this.$emit('view-secrets', item);
  }

  get clients() {
    return this.items.map((c: Client) => {
      const role: Role = this.roles.find((r: Role) => c.role === r._id)!;
      return {
        ...c,
        role: role ? role.name : c.role,
        venues: c.venues.map((v: string) => {
          const venue: Venue = this.venues.find((venue: Venue) => venue._id === v)!;
          return venue ? venue.name : v;
        }),
      };
    });
  }
}
