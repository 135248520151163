
import { Component, Vue } from 'vue-property-decorator';
import VWrapper from '@/components/shared/VWrapper.vue';
import { namespace } from 'vuex-class';
import { Venue } from '@/interfaces/models/Venue';
import Notification from '@/mixins/Notification';
import VEmptyState from '@/components/shared/VEmptyState.vue';
import TagTable from '@/components/tag/TagTable.vue';
import ClientTable from '@/components/client/ClientTable.vue';
import { Role } from '@/interfaces/models/Role';
import { Client as ClientModel } from '@/interfaces/models/Client';
import { mixins } from 'vue-class-component';

const app = namespace('app');
const role = namespace('role');
const client = namespace('client');

@Component({
  components: { ClientTable, TagTable, VEmptyState, VWrapper },
})
export default class Client extends mixins(Notification) {
  @app.State('venues') public venues!: Venue[];

  @role.State('items') public roles!: Role[];
  @client.State('items') public items!: ClientModel[];
  @client.State('active') public client!: ClientModel;

  @client.Action('fetch') public getClients!: any;
  @client.Action('addSecret') public addSecret!: any;
  @client.Action('showSecrets') public showSecrets!: any;

  @client.State((state) => state.pagination.total) public total!: number;
  @role.Action('fetch') public getRoles!: any;

  public secretDialog: boolean = false;

  public async viewSecrets(item: ClientModel) {
    await this.showSecrets({ id: item._id });
    this.secretDialog = true;
  }

  public async storeSecret(item: ClientModel) {
    this.$startLoading('client');
    await this.addSecret(item);
    this.notifySuccess('client.notification.addSecret');
    this.$stopLoading('client');
  }

  public async mounted() {
    this.$startLoading('role');
    this.$startLoading('client');
    await this.getRoles();
    await this.getClients({ page: 1 });
    this.$stopLoading('role');
    this.$stopLoading('client');
  }
}
