export default [
  {
    text: 'client.table.name',
    value: 'name',
  },
  {
    text: 'client.table.type',
    value: 'type',
  },
  {
    text: 'client.table.clientId',
    value: 'clientId',
  },
  {
    text: 'client.table.role',
    value: 'role',
  },
  {
    text: 'client.table.venues',
    value: 'venues',
    type: 'array',
  },
  {
    text: 'client.table.isBlocked',
    value: 'isBlocked',
    type: 'boolean',
  },
  {
    text: 'client.table.canRegister',
    value: 'canRegister',
    type: 'boolean',
  },
  {
    text: 'client.table.whitelist',
    value: 'whitelist',
    type: 'array',
  },
];
